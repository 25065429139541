<ald-form-element-header
    *ngIf="label"
    [id]="id"
    [label]="label"
    [tip]="tip"
    [hint]="hint"
    [required]="required">
</ald-form-element-header>

<div class="u-relative">
    <ald-input  #aldInput
                [ngClass]="[
                            disabled ? 'ald-select--disabled' : '',
                            size ? 'ald-select--multiple' : '',
                            control?.errors && (control?.touched) && closedOption ? 'ald-select--error' : ''
                        ]"
                (focus)="onFocus()"
                [disabled]="disabled"
                [required]="required"
                [disableError]="!closedOption"
                [disableErrorMessage]="true"
                [(ngModel)]="selectedItem.label"
                (ngModelChange)="filterItems($event)"
                (click)="openOptions()">

    </ald-input>
    <div
        class="ald-select__arrow-icon"
        [class.u-opacity-50]="disabled">
        <ald-icon icon="keyboard_arrow_down" iconClass="material-icons"></ald-icon>
    </div>
</div>

<ald-form-group-errors
    *ngIf="closedOption"
    [control]="control"
    [label]="label"
    [requiredErrorMessage]="requiredErrorMessage">
</ald-form-group-errors>

<ng-template cdkPortal>
    <div class="ald-select--panel u-overflow-auto" style="max-height: 225px">
        <div #options *ngFor="let item of filteredOptions" class="ald-select--panel--option u-px-3 u-py-2" (click)="onSelectItem(item)">
            {{ item.label }}
        </div>
    </div>
</ng-template>
