import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    Component, 
    EventEmitter, 
    Input, 
    Output, 
    ViewChild, 
    OnInit, 
    ViewChildren, 
    QueryList, 
    SimpleChanges, 
    OnChanges, 
    OnDestroy, 
    Directive, 
    TemplateRef, 
    ContentChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AldOptionItem } from '@al/design-patterns/common';
import { AldDropdownComponent, AldMultiselectComponent } from '@al/design-patterns/forms';
import { ColumnDef, ColumnFilter, ColumnFilterValue, TableColumnDecorator } from '../types';


@Directive({
    selector: '[aldTableHeaderContent]'
})
export class AldTableHeaderContentDirective {
    constructor(public templateRef: TemplateRef<unknown>) { }
}

@Component({
    selector: 'ald-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
})
export class AldTableHeaderComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('columnDropdown') columnConfigDropdown: AldDropdownComponent;
    @ViewChildren(AldMultiselectComponent) filterComponents: QueryList<AldMultiselectComponent>;
    @ContentChild(AldTableHeaderContentDirective) content: AldTableHeaderContentDirective;

    /** Provide columns to the table header to show the column sorting, hiding dropdown */
    @Input() columns?: ColumnDef[];
    @Input() defaultColumns?: ColumnDef[];

    /** Provide filters to enable the filters feature */
    @Input() filters?: AldOptionItem[];
    @Input() emitFiltersOnChange: boolean = true;

    /** Show or hide Search input */
    @Input() showSearch?: boolean = true;
    @Input() searchTerm?: string;
    @Input() isLoading = false;

    @Output() didApplyColumnConfig: EventEmitter<ColumnDef[]> = new EventEmitter();
    @Output() didSearch: EventEmitter<string> = new EventEmitter();
    @Output() didSearchFilter: EventEmitter<any> = new EventEmitter();
    @Output() didFilter: EventEmitter<{isInitialValue: boolean, filters: ColumnFilter[]}> = new EventEmitter();
    @Output() didClearAllFilters: EventEmitter<void> = new EventEmitter();

    tableSearchControl = new FormControl('');
    columnSearchControl = new FormControl('');

    columnsList: AldOptionItem[];
    columnsSubject$: BehaviorSubject<AldOptionItem[]> = new BehaviorSubject<AldOptionItem[]>([]);
    filteredColumnList: AldOptionItem[];
    temporarySelectedColumns = [];

    filterList: AldOptionItem[] = [];
    filtersSubject$: BehaviorSubject<{isInitialValue?: boolean, options:AldOptionItem[]}> = new BehaviorSubject<{isInitialValue?: boolean, options:AldOptionItem[]}>({options: []});

    displayFilters = false;
    filtersToggleButtonLabel = 'Filters';
    filtersSelected = false;
    isClearingAll = false;

    columnDecorator?: TableColumnDecorator;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filters']) {
            this.filtersSubject$.next({isInitialValue: changes['filters'].firstChange, options: changes.filters.currentValue});
        }
        if (changes.searchTerm) {
            this.tableSearchControl.setValue(changes.searchTerm.currentValue, {emitEvent: false});
        }
        if ( 'columns' in changes && this.columns?.length > 0 ) {
            if(this.columnDecorator) {
                let rewritten = this.columnDecorator.read( this.columns );
                if ( rewritten ) {
                    this.columns = rewritten;
                }
            }
            // Build the column options controller
            const columns = this.buildColumnOptionsController(this.columns);

            // Initialise the column options with the input column options
            this.columnsList = [... columns];
            this.columnsSubject$.next(columns);
        }
    }

    ngOnInit(): void {
        if (this.filters) {
            // Initialise the filters list and subscribe to changes
            this.filtersSubject$.subscribe((change: {isInitialValue?: boolean, options:AldOptionItem[]}) => {
                this.filterList = [...change.options];
                this.filterList.forEach((filter) => {
                    this.applyFilters(filter);
                });
                if (this.emitFiltersOnChange) {
                    this.emitFilters(change.isInitialValue);
                }
            });
        }

        // subscription to the table search, emitting the search term to the table component to handle
        this.tableSearchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe((searchTerm: string) => {
            this.didSearch.emit(searchTerm);
        });

        if(this.searchTerm) {
            this.tableSearchControl.setValue(this.searchTerm, {emitEvent: false})
        }

        this.columnsSubject$.subscribe((columns) => {
            this.filteredColumnList = columns;
        });

        // subsription to the column search to update the column options
        this.columnSearchControl.valueChanges.subscribe((searchTerm: string) => {
            const columns = [...this.columnsList];
            let filteredColumns: AldOptionItem[];

            if (!searchTerm) {
                filteredColumns = columns;
            } else {
                const filteredResults = columns.filter((value: any) => {
                    return Object.values(value).reduce((prev, curr) => {
                        return (
                            prev ||
                            curr
                                .toString()
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                        );
                    }, false);
                });
                filteredColumns = filteredResults;
            }

            this.columnsSubject$.next(filteredColumns);
        });
    }

    // Drag & Drop to rearrange column order
    public dropColumn(item: CdkDragDrop<AldOptionItem>): void {
        // Forces the current index (where the item was dropped) to only be after the first (disabled item).
        if (item.currentIndex > 0) {
            moveItemInArray(
                this.columnsList,
                item.previousIndex,
                item.currentIndex
            );
        } else {
            moveItemInArray(
                this.columnsList,
                item.previousIndex,
                item.currentIndex + 1
            );
        }

        this.columnsSubject$.next(this.columnsList);
    }

    public didSelectColumn($event: boolean, id: string): void {
        // Temporarily hold the selected values.
        this.temporarySelectedColumns.push(id);

        const foundColumn = [...this.filteredColumnList].find(
            ({ value }) => value === id
        );
        if (!foundColumn) {
            return;
        }
        foundColumn.selected = $event;
        this.columnsSubject$.next(this.filteredColumnList);
    }

    public applyColumnConfig(): void {
        this.columnSearchControl.reset();

        const displayColumns: ColumnDef[] = this.columnsList.map((col) => {
            const columnDef = this.columns.find(({ field }) => {
                return field === col.value;
            });

            columnDef.hidden = !col.selected;
            return columnDef;
        });

        // Clean up and emit columns
        this.temporarySelectedColumns = [];
        this.didApplyColumnConfig.emit(displayColumns);
        this.columnConfigDropdown.close();
        if(this.columnDecorator) {
            this.columnDecorator.write(displayColumns);
        }
        // Updating the filter's order as well
        this.filterList = this.columnsList.map(col => this.filterList.find(item => item.value === col.value)).filter(filter => filter);
    }

    public cancelColumnConfig(): void {

        // For each of the temporary values, revert the change.
        this.temporarySelectedColumns.forEach(element => {
            const column = this.columnsList.find(({ value }) => {
                return value === element;
            });
            column.selected = !column.selected;
        });

        // Clean up
        this.temporarySelectedColumns = [];
        this.columnSearchControl.reset();
        this.columnConfigDropdown.close();
        this.columnsSubject$.next(this.filteredColumnList);
    }

    public resetColumnConfig(): void {
        // Shouldn't be possible, given the template ngIf, but just in case. Nothing to do if there is no default.
        if (!this.defaultColumns || this.defaultColumns.length < 2) {
            return;
        }
        const defaultColumnsNames = this.defaultColumns.map( a => a.header );

        // First sort columns that exist in default by index, then sort the rest alphabetically.
        this.columnsList.sort( (a, b) => {
            if (defaultColumnsNames.indexOf( a.label ) >= 0 && defaultColumnsNames?.indexOf( b.label ) >= 0) {
                return defaultColumnsNames.indexOf( a.label ) - defaultColumnsNames.indexOf( b.label );
            } else if (defaultColumnsNames.indexOf( a.label ) >= 0) {
                return -1;
            } else if (defaultColumnsNames.indexOf( b.label ) >= 0) {
                return 1;
            }
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0; // Dread trinary expression! Danger bongos!
        } );

        // Select the columns that are in the default list (but not hidden), and unselect the rest.
        this.columnsList.forEach( (col) => {
            const columnDef = this.defaultColumns.find( ({header}) => {
                return header === col.label;
            } );
            if (!columnDef) {
                col.selected = false;
            } else {
                col.selected = !columnDef?.hidden;
            }
        } );

        this.applyColumnConfig();
    }

    // Maps the list of column options from array of columnDefs.
    public buildColumnOptionsController(columns: ColumnDef[]): AldOptionItem[] {
        return columns.map((column) => {
            return {
                label: column.header,
                value: column.field,
                selected: !column.hidden,
            };
        })
    };

    /**
     * Filter model has changed. Apply the updated filter to the
     * filters array then bulk emit the filter set.
     *
     * NOTE: Clearing all filters fires an ngModel update on all filters which results
     * in multiple events being fired. Put a guard in place that stop filter updates
     * being emitted when performing a clearAll.
     * clearAll fires its own clear all event and that's the only event
     * we want fired in that circumstance
     * @param {AldOptionItem} filter - The item changed
     * @returns void
     */
    public filterUpdated(filter: AldOptionItem): void {
        this.applyFilters(filter);
        if (!this.isClearingAll) {
            this.emitFilters();
        }
    }

    /**
     * Converts the set of AldOption items into a consumable Filters array
     * and emits them.
     */
    public emitFilters(isInitialValue: boolean = false): void {
        this.didFilter.emit({isInitialValue: isInitialValue, filters: this.filters.map((filter: AldOptionItem) => {
            return <ColumnFilter>{
                field: filter.value,
                rawField: filter.rawValue,
                values: filter.items.map((item: AldOptionItem) => {
                    return <ColumnFilterValue>{
                        label: item.label,
                        value: item.value,
                        selected: item.selected
                    }
                })
            }
        })});
    }

    public applyFilters(filter: AldOptionItem): void {
        if (!filter.items) { return; }

        let filterCount = 0;

        filter.items.forEach((item) => {
            if (item.selected) {
                filterCount++;
            }
        });

        // saves the filter label to the filter's description field only if it's empty
        if (!filter.description) {
            filter.description = filter.label;
        }

        // update the filter label with a count, or if no count, set the label to the description value saved earlier
        filter.label = filterCount
            ? filter.description + ' (' + filterCount + ')'
            : filter.description;
        filter.selected = filterCount ? true : false;

        this.toggleFiltersButtonState();
    }

    // Clear all filters
    public clearAll() {
        this.isClearingAll = true;
        this.filterComponents.forEach(filter => {
            filter.clearSelectedOptions();
        });

        this.isClearingAll = false;
        this.didClearAllFilters.emit();
    }

    // make the "Filters" button selected or not if at least one filter is applied.
    private toggleFiltersButtonState() {
        let hasFiltersApplied = 0;

        this.filterList.forEach((filterType) => {
            if (filterType.selected) {
                hasFiltersApplied++;
            }
        });

        this.filtersSelected = hasFiltersApplied ? true : false;
        this.filtersToggleButtonLabel = hasFiltersApplied
            ? 'Filters (' + hasFiltersApplied + ')'
            : 'Filters';
    }

    /** Destroy Subjects */
    ngOnDestroy(): void {
        this.columnsSubject$.complete();
        this.filtersSubject$.complete();
    }
}
